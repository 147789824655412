const buttonLabelKey = (key: string) => `button_label.${key}`
const navbarKey = (key: string) => `navbar.${key}`
const formFieldLabelKey = (key: string) => `form_field_label.${key}`

export const i18nKey = {
  welcome: 'welcome',
  navbar: {
    main_page: navbarKey('main_page'),
    insurance_information: navbarKey('insurance_information'),
    company_welfare: navbarKey('company_welfare'),
    e_claim: navbarKey('e_claim')
  },
  button_label: {
    login: buttonLabelKey('login'),
    confirm: buttonLabelKey('confirm'),
    view_detail: buttonLabelKey('view_detail'),
    detail: buttonLabelKey('detail'),
    download: buttonLabelKey('download'),
    cancel: buttonLabelKey('cancel'),
    request_OTP_again: buttonLabelKey('request_OTP_again'),
    send_request_withdraw: buttonLabelKey('send_request_withdraw'),
    select_insurance_plan: buttonLabelKey('select_insurance_plan'),
    select_plan: buttonLabelKey('select_plan'),
    log_out: buttonLabelKey('log_out'),
    download_document: buttonLabelKey('download_document'),
    claim: buttonLabelKey('claim'),
    close: buttonLabelKey('close'),
    claim_request: buttonLabelKey('claim_request'),
    edit_employee_logo: buttonLabelKey('edit_employee_logo'),
    update: buttonLabelKey('update'),
    edit: buttonLabelKey('edit'),
    cancel_claim_request: buttonLabelKey('cancel_claim_request')
  },
  form_field_label: {
    language: formFieldLabelKey('language'),
    company: formFieldLabelKey('company'),
    email: formFieldLabelKey('email'),
    telephone_number: formFieldLabelKey('telephone_number'),
    age_unit: formFieldLabelKey('age_unit'),
    opd: formFieldLabelKey('opd'),
    currency: formFieldLabelKey('currency'),
    month: formFieldLabelKey('month'),
    phone_number: formFieldLabelKey('phone_number'),
    first_name: formFieldLabelKey('first_name'),
    last_name: formFieldLabelKey('last_name'),
    payment_date: formFieldLabelKey('payment_date'),
    payment_time: formFieldLabelKey('payment_time'),
    product_service_detail: formFieldLabelKey('product_service_detail'),
    select_attach_file: formFieldLabelKey('select_attach_file'),
    withdraw_amount: formFieldLabelKey('withdraw_amount'),
    full_name: formFieldLabelKey('full_name'),
    ID_card_number: formFieldLabelKey('ID_card_number'),
    date_of_birth: formFieldLabelKey('date_of_birth'),
    age: formFieldLabelKey('age'),
    gender: formFieldLabelKey('gender'),
    used_point: formFieldLabelKey('used_point'),
    work_position: formFieldLabelKey('work_position'),
    cost_in_receipt: formFieldLabelKey('cost_in_receipt'),
    identity_number: formFieldLabelKey('identity_number'),
    iden_or_passport: formFieldLabelKey('iden_or_passport'),
    area: formFieldLabelKey('area'),
    department: formFieldLabelKey('department'),
    team: formFieldLabelKey('team')
  },
  confirm_withdraw_claim_modal: {
    confirm_withdraw_claim: 'confirm_withdraw_claim_modal.confirm_withdraw_claim',
    approval_take_time: 'confirm_withdraw_claim_modal.approval_take_time',
    work_day: 'confirm_withdraw_claim_modal.work_day'
  },
  home_welfare: {
    title_2: 'home_welfare.title_2',
    description_2: 'home_welfare.description_2',
    title_3: 'home_welfare.title_3',
    description_3: 'home_welfare.description_3',
    title_4: 'home_welfare.title_4',
    description_4: 'home_welfare.description_4',
    withdraw_and_claim: 'home_welfare.withdraw_and_claim',
    title_5: 'home_welfare.title_5',
    description_5: 'home_welfare.description_5'
  },
  confirm_select_insurance_modal: {
    title: 'confirm_select_insurance_modal.title',
    content: 'confirm_select_insurance_modal.content'
  },
  confirm_cancel_claim_modal: {
    title: 'confirm_cancel_claim_modal.title',
    content: 'confirm_cancel_claim_modal.content'
  },
  validation_schema: {
    withdraw_amount_validation: 'validation_schema.withdraw_amount_validation'
  },
  until_age: 'until_age',
  pay: 'pay',
  have_question_contact_hr: 'have_question_contact_hr',
  powered_by: 'powered_by',
  specify_OTP: 'specify_OTP',
  OTP_has_send_to: 'OTP_has_send_to',
  OTP_has_send_to_sms: 'OTP_has_send_to_sms',
  not_receive_OTP: 'not_receive_OTP',
  insurance_claim: 'insurance_claim',
  medical_expense_list: 'medical_expense_list',
  withdraw_history: 'withdraw_history',
  list: 'list',
  status: 'status',
  withdraw_date_time: 'withdraw_date_time',
  withdraw_date: 'withdraw_date',
  receipt_date: 'receipt_date',
  receipt_time: 'receipt time',
  withdraw_time: 'withdraw_time',
  withdraw_amount: 'withdraw_amount',
  confirm_information: 'confirm_information',
  main_page: 'main_page',
  requester_withdraw_detail: 'requester_withdraw_detail',
  withdraw_detail: 'withdraw_detail',
  maximum_withdraw: 'maximum_withdraw',
  currency_per_time: 'currency_per_time',
  request_withdraw_document: 'request_withdraw_document',
  ID_card_photo: 'ID_card_photo',
  withdraw_list_receipt: 'withdraw_list_receipt',
  company_welfare: 'company_welfare',
  company_welfare_list: 'company_welfare_list',
  point_usage_history: 'point_usage_history',
  point_amount: 'point_amount',
  remaining_point: 'remaining_point',
  point: 'point',
  point_usage_detail: 'point_usage_detail',
  use: 'use',
  plan_selection_time_left: 'plan_selection_time_left',
  day: 'day',
  select_plan_spouse_year: 'select_plan_spouse_year',
  select_insurance_plan_period: 'select_insurance_plan_period',
  spouse_detail: 'spouse_detail',
  select_plan_children_year: 'select_plan_children_year',
  children_detail: 'children_detail',
  select_insurance_group_year: 'select_insurance_group_year',
  your_selected_plan: 'your_selected_plan',
  updated_at: 'updated_at',
  selected_plan: 'selected_plan',
  available_points: 'available_points',
  employee_insurance_plan: 'employee_insurance_plan',
  employee_insurance: 'employee_insurance',
  spouse_and_children_insurance: 'spouse_and_children_insurance',
  spouse_and_children_insurance_plan: 'spouse_and_children_insurance_plan',
  employee_welfare: 'employee_welfare',
  already_have: 'already_have',
  plan_selection_timeout: 'plan_selection_timeout',
  please_contact_hr: 'please_contact_hr',
  customer_service: 'customer_service',
  request_OTP_again_in: 'request_OTP_again_in',
  second: 'second',
  minute: 'minute',
  life_insurance: 'life_insurance',
  accidental_death_and_dismemberment_indemnity: 'accidental_death_and_dismemberment_indemnity',
  permanent_disability_indemnity_total: 'permanent_disability_indemnity_total',
  hospital_and_board_per_day: 'hospital_and_board_per_day',
  icu_cost_per_day: 'icu_cost_per_day',
  maximum_days_for_hospital_IPD: 'maximum_days_for_hospital_IPD',
  maximum_days_for_ICU_room: 'maximum_days_for_ICU_room',
  nursing_fees: 'nursing_fees',
  general_hospital_expenses: 'general_hospital_expenses',
  ambulance_fees: 'ambulance_fees',
  opd_follow_up_treatment_after_IPD_stay: 'opd_follow_up_treatment_after_IPD_stay',
  surgery_fee: 'surgery_fee',
  physician_hospital_visit_fee: 'physician_hospital_visit_fee',
  accident_cost_for_OPD_per_accident: 'accident_cost_for_OPD_per_accident',
  duration_after_OPD_treatment: 'duration_after_OPD_treatment',
  specialist_consultation_fee: 'specialist_consultation_fee',
  hb_incentive_benefit: 'hb_incentive_benefit',
  maximum_HB_incentive_benefits: 'maximum_HB_incentive_benefits',
  opd_treating_cost: 'opd_treating_cost',
  dental_cost: 'dental_cost',
  other_coverage_detail: 'other_coverage_detail',
  price: 'price',
  hospital_and_board: 'hospital_and_board',
  plan_detail: 'plan_detail',
  manage: 'manage',
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved',
  need_info: 'need_info',
  male: 'male',
  female: 'female',
  welfare_text: 'welfare_text',
  welfare_2ndline_text: 'welfare_2ndline_text',
  year: 'year',
  benefit_description: 'benefit_description',
  crop_image: 'crop_image',
  point_history: 'point_history',
  reason: 'reason',
  medical_result: 'medical_result',
  more_detail: 'more_detail',
  compare_medical_result: 'compare_medical_result',
  medical_result_overview: 'medical_result_overview',
  BMI: 'BMI',
  cholesterol: 'cholesterol',
  blood_pressure: 'blood_pressure',
  blood_sugar: 'blood_sugar',
  result: 'result',
  doctor_advice: 'doctor_advice',
  medical_result_list: 'medical_result_list'
}
